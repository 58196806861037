import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { organiseLocations } from '../components/helper';
import '../assets/styles/pages/sitemap.scss';

/* global window */
const SiteMap = () => {
  const data = useStaticQuery(graphql`
    query {
      sitemap {
        blogList {
          text
          url
        }
        carList {
          text
          url
        }
        mediaList {
          text
          url
        }
        serviceList {
          text
          url
        }
        top {
          text
          url
        }
        seo {
          canonical
          description
          heading
          og {
            description
            image
            title
            type
            url
          }
          title
        }
      }
      allSuburbList {
        edges {
          node {
            url
            state
            text
            slug
          }
        }
      }
      allStateList {
        edges {
          node {
            id
            url
            text
          }
        }
      }
    }
  `);

  const { seo, blogList, top, mediaList, serviceList, carList } = data.sitemap;
  const { allSuburbList, allStateList } = data;
  const [states, setStates] = useState([]);

  useEffect(() => {
    setStates(organiseLocations(allSuburbList.edges, allStateList.edges));
  }, [allStateList]);

  return (
    <Layout wrapperClass="" showSiteIndex={true} hideHeaderFooter={true}>
      <SEO
        title={seo.title}
        description={seo.title}
        canonical={seo.canonical}
        stucturedData=""
        ogTitle={seo.og.title}
        ogDescription={seo.og.description}
        ogImage={seo.og.image}
        ogType={seo.og.type}
        ogUrl={seo.og.url}
      />

      <div className="py-7 container main-wrapper sm">
        {top && (
          <ul className="sitemap-list">
            {top.map((item) => {
              return (
                <li key={item.url}>
                  <a href={item.url}>
                    {item.text}
                  </a>
                </li>
              )
            })}
          </ul>
        )}
        {blogList && (
          <ul className="sitemap-list">
            {blogList.map((item) => {
              return (
                <li key={item.url}>
                  <a href={item.url}>
                    {item.text}
                  </a>
                </li>
              )
            })}
          </ul>
        )}
        {mediaList && (
          <ul className="sitemap-list">
            {mediaList.map((item) => {
              return (
                <li key={item.url}>
                  <a href={item.url}>
                    {item.text}
                  </a>
                </li>
              )
            })}
          </ul>
        )}
        {Object.keys(states).length !== 0 && (
          Object.keys(states).map((key) => {
            const data = states[key];
            return (
              <ul key={key} className="sitemap-list">
                <li>
                  <a href={data.data.url}>
                    {data.data.text}
                  </a>
                </li>
                {data.suburbs.map(({ node }) => (
                  <li key={node.url}>
                    <a href={node.url}>
                      {node.text}
                    </a>
                  </li>
                ))}
              </ul>
            )
          })
        )}
        {serviceList && (
          <ul className="sitemap-list">
            {serviceList.map((item) => {
              return (
                <li key={item.url}>
                  <a href={item.url}>
                    {item.text}
                  </a>
                </li>
              )
            })}
          </ul>
        )}
        {carList && (
          <ul className="sitemap-list">
            {carList.map((item) => {
              return (
                <li key={item.url}>
                  <a href={item.url}>
                    {item.text}
                  </a>
                </li>
              )
            })}
          </ul>
        )}

      </div>
    </Layout>
  );
};

export default SiteMap;